import React from 'react';
import Picture from "./Picture";


class RegularPicture extends React.Component {
    render() {
        return (
            <Picture key={this.props.data.created}  pictureClass={'regular-picture'} { ...this.props } />
        );
    }
}

export default RegularPicture;
