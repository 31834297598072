import React from 'react';
import './Home.css';
import BigPicture from "./BigPicture";
import RegularPicture from "./RegularPicture";
import FullscreenPicture from "./FullscreenPicture";
import { Fetch } from 'react-data-fetching'
import Loader from "./Loader";

class Home extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isFullscreenVisible: false,
      fullscreenData: []
    }

    this.loadFullscreen = this.loadFullscreen.bind(this);
    this.hideFullscreen = this.hideFullscreen.bind(this);
  }

  renderRegularPictures(data) {
    return data.map(pictureData => {
      return <RegularPicture key={pictureData.created} clickFunc={this.loadFullscreen} data={pictureData} />;
    });
  }

  loadFullscreen(data) {
    this.setState({
      isFullscreenVisible: true,
      fullscreenData: data
    })
  }

  hideFullscreen() {
    this.setState({
      isFullscreenVisible: false
    })
  }

  renderPictures(data) {
    if (!data || data.length === 0) {
      return null;
    }

    let firstPicture = data[0];
    let regularPictureData = [];
    if (data.length > 1) {
      regularPictureData = data.slice(1, data.length);
    }

    return (
        <div>
        <BigPicture key={'big-picture'} data={firstPicture} />
          <div className={'pictures'}>
            {this.renderRegularPictures(regularPictureData)}
          </div>
        </div>
    );
  }

  render() {
    return (
      <div className="nextlevelgallery-live">

        <Fetch
            loader={<Loader />}
            url="https://api.nextlevelgallery.com/art"
            timeout={5000}
        >
          {({ data }) => this.renderPictures(data)}
        </Fetch>
        <FullscreenPicture
            isFullscreen={this.state.isFullscreenVisible}
            clickFunc={this.hideFullscreen}
            data={this.state.fullscreenData} />


      </div>
    );
  }
}

export default Home;
