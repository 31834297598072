import React from 'react';
import Picture from "./Picture";


class FullscreenPicture extends React.Component {
    render() {
        if (!this.props.isFullscreen) { return null }

        return (
            <Picture key={this.props.data.created} pictureClass={'fullscreen-picture'} { ...this.props } />
        );
    }
}

export default FullscreenPicture;
