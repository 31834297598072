import React from 'react';
import './Picture.css';
import LazyLoad from 'react-lazyload';
import moment from 'moment';

class Picture extends React.Component {

    picturePathFromFilename(filename) {
        return "//cdn.nextlevelgallery.com/" + filename;
    }

    render() {
        let pictureData = this.props.data;
        let pictureClass = this.props.pictureClass || '';
        let pictureCreated = moment(new Date(pictureData.created)).fromNow();
        let pictureTitle = pictureData.title || "";
        let picturePath = this.picturePathFromFilename(pictureData.filename);
        let clickFunc = this.props.clickFunc || (() => {});
        return (
            <div onClick={() => { clickFunc(pictureData) }} key={picturePath} className={'picture-main-container ' + pictureClass}>
                <div className={'picture-inner-container'}>
                    <div className={'picture-img-container'}>
                        <LazyLoad height={232} offset={100} once >
                        <img className={'picture'} src={picturePath} alt={pictureTitle}/>
                        </LazyLoad>
                    </div>
                    <div className={'picture-title'}>
                        {pictureTitle}
                    </div>
                    <div className={'picture-created'}>
                        {pictureCreated}
                    </div>
                </div>
            </div>
        );
    }
}

export default Picture;