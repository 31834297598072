import React from 'react';
import Picture from "./Picture";


class BigPicture extends React.Component {
    render() {
        return (
            <Picture pictureClass={'big-picture'} { ...this.props } />
        );
    }
}

export default BigPicture;
